<template lang="pug">
vs-sidebar.items-no-padding(
  position-right,
  v-model="active",
  parent="body",
  default-index="55555551"
)
  .h-100(
    v-if="isLoadingIntervention || isLoadingPool || isLoadingCollaborator || isUpdatingIntervention || isDeletingIntervention"
  )
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails de l'intervention...
  .h-full(v-else-if="intervention.id")
    .sidebar-header.py-1.px-2
      .d-flex.align-items-center
        div
          h3 {{ `${collaborator.firstName} ${collaborator.lastName}` }}
          small(v-if="collaborator.job") {{ `( ${collaborator.job.label} )` }}
      feather-icon.close-icon.cursor-pointer(
        icon="XIcon",
        size="24",
        @click.stop="active = !active"
      )
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center
        b-button.btn-icon(
          v-if="archived",
          variant="warning",
          @click="restoreInterventionLocal(intervention.id, intervention.label)"
        )
          feather-icon(icon="ArrowDownIcon")
          | Restaurer
        b-button.btn-icon(
          v-else,
          variant="primary",
          @click="editIntervention(intervention.id, intervention.customer.lastName, intervention.customer.civility, intervention.dateTime)",
          :disabled="archived"
        )
          feather-icon(icon="EditIcon")
          | Modifier
      b-button-group
        b-button.btn-icon(
          variant="outline-danger",
          @click="deleteInterventionLocal(intervention.id, collaborator.firstName + ' ' + collaborator.lastName)"
          v-if="archived"
        )
          feather-icon.mr-50(icon="TrashIcon")
          | Supprimer d&eacute;finitivement
        b-button(v-else variant="outline-danger" class="btn-icon" @click="archiveInterventionLocal(intervention.id, collaborator.firstName + ' ' + collaborator.lastName)")
          feather-icon(icon='ArchiveIcon')
    vs-divider.m-0
    .sidebar-content
      div(
        v-show="intervention.poolId || intervention.dateTime || intervention.customerId || intervention.interventionTypes"
      )
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Informations
        ul.listTab
          li(v-show="intervention.dateTime")
            span Date
            span {{ formatDateTime(intervention.dateTime) }}
          li(v-show="intervention.interventionTypes")
            span Type d'intervention
            span {{ intervention.interventionTypes.label }}
          li(v-show="intervention.poolId")
            span Adresse
            span {{ pool.address }}
          li(v-show="intervention.poolId")
            span Ville
            span {{ pool.city }}
          li(v-show="intervention.poolId")
            span Code postal
            span {{ pool.postalCode }}
          li(v-show="intervention.customer")
            span Client
            span {{ intervention.customer.firstName + " " + intervention.customer.lastName }}
          li(v-show="intervention.poolId")
            span Nom du bassin
            span {{ pool.label }}
          li(v-show="intervention.customer")
            span Tel.
            span {{ intervention.customer.tel1 }}
        vs-divider
      div(
        v-show="intervention.observation || intervention.indication || intervention.status >= 0"
      )
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Détails
        ul.listTab
          li(v-show="intervention.status >= 0")
            span Status
            span {{ findStatus(intervention.status) }}
          li(v-show="intervention.indications")
            span Indication
            span {{ intervention.indications }}
          li(v-show="intervention.observations")
            span Observation
            span {{ intervention.observations }}
        vs-divider
      div(v-show="intervention.steps.length > 0")
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Contenu de l'intervention
        ul.listTab(:key="listKey")
          div(v-for="(step, index) in intervention.steps", :key="''+step.step.id+index")
            li.cursor-pointer.mt-1(
              @click.stop="setVisible(index)",
              style="border-bottom: 1px solid #e5e5e5"
            )
              span Étape
              span {{ step.step.label }}
              feather-icon(
                v-if="!visibleSteps[index]",
                icon="ArrowDownCircleIcon"
              )
              feather-icon(v-else, icon="ArrowUpCircleIcon")
            ul.listTab.border(
              :class="{ 'd-none': !visibleSteps[index] }",
              style="margin-left: 70px",
              v-if="step.missions"
            )
              li(
                v-for="mission in step.missions",
                :key="'' + mission.mission.id + mission.stepId"
              )
                span.w-100 {{ mission.mission.label }}
                span(v-if="mission.missionType") {{ mission.missionType.label }}
            ul.listTab.border(
              style="margin-left: 70px",
              v-else,
              :class="{ 'd-none': !visibleSteps[index] }"
            )
              li(v-if="visibleSteps[index]")
                span.w-100 Aucune tâche dans cette étape
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      archived: false,
      status: [
        { id: 0, label: "A faire" },
        { id: 1, label: "En cours" },
        { id: 2, label: "Clôturée" },
      ],
      visibleSteps: [],
      listKey: 0,
    };
  },
  computed: {
    ...mapGetters([
      "intervention",
      "isUpdatingIntervention",
      "isLoadingIntervention",
      "isDeletingIntervention",
      "isOpenSidebarIntervention",
      "pool",
      "collaborator",
      "isLoadingPool",
      "isLoadingCollaborator",
      "stepsList"
    ]),
    active: {
      get() {
        return this.isOpenSidebarIntervention;
      },
      set(val) {
        this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", val);
      },
    },
  },
  methods: {
    setVisible(index) {
      this.visibleSteps[index] = !this.visibleSteps[index];
      this.listKey++;
    },
    ...mapActions([
      "updateIntervention",
      "deleteIntervention",
      "isArchivingIntervention",
      "archiveIntervention",
      "restoreIntervention",
      "fetchIntervention",
      "fetchInterventionArchived",
      // "fetchCustomer",
      "fetchCollaborator",
      "fetchPool",
    ]),
    findStatus(id) {
      if (this.status.find((elem) => elem.id === id)) {
        return this.status.find((elem) => elem.id === id).label;
      } else {
        return "Aucun";
      }
    },
    getInterventionArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.fetchInterventionArchived(id).then((res) => {
          this.fetchCollaborator(res.collaboratorId);
          this.fetchPool(res.poolId);
        });
      } else {
        this.intervention = {};
      }
    },
    getInterventionDetails(id) {
      this.archived = false;
      this.fetchIntervention(id).then((res) => {
        this.fetchCollaborator(res.collaboratorId);
        this.fetchPool(res.poolId);
      });
    },
    async editIntervention(id, customer, customerCivility, date) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", false);
      this.$router.push({
        name: "edit-intervention",
        params: {
          id: id,
          title:
            "Intervention du " +
            this.formatDateTime(date) +
            " pour " +
            (customerCivility == 0
              ? "M."
              : customerCivility == 1
              ? "Mme."
              : customerCivility == 3
              ? "Soc."
              : "Mx.") +
            " " +
            customer,
          tips:
            "Intervention du " +
            this.formatDateTime(date) +
            " pour " +
            (customerCivility == 0
              ? "M."
              : customerCivility == 1
              ? "Mme."
              : "Mx.") +
            " " +
            customer,
        },
      });
    },
    archiveInterventionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver l\'intervention de "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveIntervention(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", false);
          }
        });
    },
    formatDateTime(dateString) {
      const dateObj = new Date(dateString);

      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Paris",
      };

      const formattedDateTime = dateObj.toLocaleString("fr-FR", options);
      return formattedDateTime;
    },
    restoreInterventionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreIntervention(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", false);
          }
        });
    },
    deleteInterventionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteIntervention(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", false);
          }
        });
    },
  },
};
</script>
