<template lang="pug">
div
  b-row.m-0
    b-col.content-header(cols="12")
      h2.content-header-title.float-left.m-0.pr-1.mr-1
        | {{ $route.meta.pageTitle }}
        span.text-danger(v-show="archive") &nbsp;(archive)
      DateRangePicker.mr-1(
        ref="pickerIntervention"
        id="dates"
        style="width: 16vw"
        :show-week-numbers="true"
        :timePicker="true"
        :opens="'right'"
        :append-to-body="true"
        :locale-data="config"
        :date-range= "dateRange"
        :auto-apply="true"
        :ranges= "defaultRange"
        @update="setDates"
        )
      v-select.mr-1#filter-status(v-model="statusFilter" :reduce="(el) => el.id" :options="status" label="label" placeholder="Filtrer par statut" @input="changeStatus")
        template(v-slot:no-options="")
          template Aucun événément trouvé
        template(#selected-option-container="{ option, deselect, multiple, disabled }")
          .d-flex.align-items-center
            .pin-status(:style="'background-color:'+(option.color!=='' ? option.color : '#0c3571')+' !important'")
            div {{option.label}}
        template(v-slot:option="option")
          .d-flex
            .pin-status(:style="'background-color:'+(option.color!=='' ? option.color : '#0c3571')+' !important'")
            div {{option.label}}
      .content-header-search.mr-1
        b-form-input#searchIntervention.w-full(
          v-model="searchIntervention",
          placeholder="Rechercher une intervention",
          v-on:input="onFilterTextBoxChanged()"
        )
      .content-header-actions
        b-button.d-flex.px-1.mr-1(
          variant="primary",
          @click="newIntervention()"
        )
          feather-icon(icon="PlusIcon")
            span.d-none.d-md-block 
          | Nouveau
        b-dropdown.bg-outline-primary.btn-group-icon(variant="outline-primary")
          template(#button-content="")
            span.material-icons-outlined.m-0.p-0 settings
          b-dropdown-item.w-full.border-bottom-grey-light(@click="archive = !archive") {{!archive ? "Voir les interventions archiv&eacute;s" : "Voir les interventions actifs" }}  
          b-dropdown-item.w-full(@click="exportDataCSV") Exporter en CSV
          b-dropdown-item.w-full(@click="exportDataXLS") Exporter en XLS
  .content-grid
    .text-center.flex-center(
      v-if="isLoadingInterventionsList || isLoadingCustomersList || isLoadingPoolsList"
    )
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | {{  &apos;Chargement en cours de la liste des interventions...&apos;  }}
    AgGridVue.ag-theme-material(
      v-else,
      style="width: 100%; height: 100%",
      :rowData="gridData",
      :gridOptions="gridOptions",
      :key="filter",
      :class="archive ? 'ag-archived' : ''",
      @grid-ready="onGridReady",
      @rowClicked="onRowClicked"
    )
  SidebarIntervention(ref="sidebarIntervention" )
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";

import { BFormGroup, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody, BButtonToolbar, BButtonGroup, BButton, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import SidebarIntervention from "@/components/intervention/SidebarIntervention";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  firstDay: 1,
};

let startToday = new Date();
startToday.setHours(0, 0, 0);

let endToday = new Date();
endToday.setHours(23, 59, 59);

let startYesterday = new Date();
startYesterday.setDate(startToday.getDate() - 1);
startYesterday.setHours(0, 0, 0);

let endYesterday = new Date();
endYesterday.setDate(endToday.getDate() - 1);
endYesterday.setHours(23, 59, 59);

let startTomorrow = new Date();
startTomorrow.setDate(startToday.getDate() + 1);
startTomorrow.setHours(0, 0, 0);

let endTomorrow = new Date();
endTomorrow.setDate(endToday.getDate() + 1);
endTomorrow.setHours(23, 59, 59);

var defaultRange = {
  Hier: [startYesterday, endYesterday],
  "Aujourd'hui": [startToday, endToday],
  Demain: [startTomorrow, endTomorrow],
  "Mois dernier": [new Date(dayjs().subtract(1, "month").startOf("month").hour(12)), new Date(dayjs().subtract(1, "month").endOf("month"))],
  "Mois prochain": [new Date(dayjs().add(1, "month").startOf("month").hour(12)), new Date(dayjs().add(1, "month").endOf("month"))],
  "Cette année": [new Date(dayjs().startOf("year").hour(12)), new Date(dayjs().endOf("year"))],
  "Année dernière": [new Date(dayjs().subtract(1, "year").startOf("year").hour(12)), new Date(dayjs().subtract(1, "year").endOf("year"))],
};

export default {
  components: {
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    SidebarIntervention,
    DateRangePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filter: false,
      sidebar: false,
      archive: false,
      activeSidebarIntervention: false,
      config: config,
      dateRange: {
        startDate: dayjs(),
        endDate: dayjs(),
      },
      defaultRange: defaultRange,
      statusFilter: null,
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          filter: true,
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 25,
      },
      status: [
        { id: 0, label: "A faire", color: "#0096C7" },
        { id: 1, label: "En cours", color: "#E3B505" },
        { id: 2, label: "Clôturée", color: "#B4B1B1" },
      ],
      gridApi: null,
      columnApi: null,
      searchIntervention: "",
    };
  },
  computed: {
    ...mapGetters([
      "interventionsList",
      "intervention",
      "poolsList",
      "poolsListArchived",
      "customersList",
      "customersListArchived",
      "collaboratorsList",
      "collaboratorsListArchived",
      "isLoadingInterventionsList",
      "isLoadingPoolsList",
      "isLoadingCustomersList",
      "isOpenSidebarIntervention",
      "interventionsListArchived",
    ]),
    gridData() {
      if (this.archive) {
        return this.$store.getters.interventionsListArchived;
      } else {
        return this.$store.getters.interventionsList;
      }
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "status",
        headerName: "Statut",
        resizable: true,
        filter: true,
        sortable: true,
        maxWidth: 100,
        getQuickFilterText: (params) => {
          if (Number.isInteger(params.value)) {
            return this.status.find((elem) => elem.id == params.value).label;
          } else {
            return "";
          }
        },
        cellRenderer: (params) => {
          return this.findStatus(params.value);
        },
      },
      {
        field: "dateTime",
        headerName: "Date",
        resizable: true,
        lockVisible: true,
        sortable: true,
        sort: "desc",
        maxWidth: 200,
        cellRenderer: (params) => {
          return this.dateConvert(params.value);
        },
        getQuickFilterText: (params) => {
          return this.dateConvert(params.value);
        },
      },
      {
        field: "customer",
        headerName: "Client",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return `${params.data.customer.firstName} ${params.data.customer.lastName}`;
        },
        getQuickFilterText: (params) => {
          return `${params.data.customer.firstName} ${params.data.customer.lastName}`;
        },
      },
      {
        field: "poolId",
        headerName: "Bassin",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
        getQuickFilterText: (params) => {
          if (params.value) {
            let poolLabel = "";
            if (this.poolsList.find((elem) => elem.id == params.value)) {
              poolLabel = this.poolsList.find((elem) => elem.id == params.value).label;
            } else {
              poolLabel = this.poolsListArchived.find((elem) => elem.id == params.value).label;
            }
            return poolLabel;
          } else {
            return "";
          }
        },
        cellRenderer: (params) => {
          return this.pool(params.value);
        },
      },
      {
        field: "collaboratorId",
        headerName: "Collaborateur",
        resizable: true,
        minWidth: 160,
        sortable: true,
        getQuickFilterText: (params) => {
          return this.getCollaboratorName(params.value);
        },
        cellRenderer: (params) => {
          return this.getCollaboratorName(params.value);
        },
      },
      {
        field: "interventionTypes.label",
        headerName: "Type d'intervention",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
      },
    ];
  },
  created() {
    this.fetchInterventionsArchivedList();
    this.fetchPoolsList();
    this.fetchPoolsListArchived();
    this.fetchCustomersList();
    this.fetchCustomersListArchived();
    this.fetchCollaboratorsList();
    this.fetchCollaboratorsListArchived();
  },
  mounted() {
    if (
      JSON.parse(localStorage.getItem("interventionFilters")) &&
      JSON.parse(localStorage.getItem("interventionFilters")).dateRange &&
      JSON.parse(localStorage.getItem("interventionFilters")).dateRange.startDate &&
      JSON.parse(localStorage.getItem("interventionFilters")).dateRange.endDate
    ) {
      this.setDates({
        startDate: JSON.parse(localStorage.getItem("interventionFilters")).dateRange.startDate,
        endDate: JSON.parse(localStorage.getItem("interventionFilters")).dateRange.endDate,
      });
    } else {
      this.setDates({
        startDate: new Date(dayjs().startOf("date").format("YYYY-MM-DD")).toISOString(),
        endDate: new Date(dayjs().endOf("date").format("YYYY-MM-DD")).toISOString(),
      });
    }
  },
  methods: {
    ...mapActions([
      "fetchInterventionsList",
      "fetchInterventionsArchivedList",
      "fetchCustomersList",
      "fetchCustomersListArchived",
      "fetchPoolsList",
      "fetchPoolsListArchived",
      "fetchCollaboratorsList",
      "fetchCollaboratorsListArchived",
      "deleteIntervention",
      "setFilter",
    ]),
    changeStatus(status) {
      console.log(status);
      if (status != null) {
        const filterInstance = this.gridApi.getFilterInstance("status");
        filterInstance.setModel({ values: [status] });
        this.gridApi.onFilterChanged();
      } else {
        this.gridApi.setFilterModel(null);
      }
    },
    async setDates(dates) {
      console.log(dates);
      let startDate = new Date(dates.startDate);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(dates.endDate);
      endDate.setHours(23, 59, 0, 0);
      let dateRange = {
        startDate: startDate,
        endDate: endDate,
      };
      await this.setFilter({
        name: "interventionFilters",
        filter: { dateRange: dateRange },
      });
      this.fetchInterventionsList({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    },
    findStatus(id) {
      if (this.status.find((elem) => elem.id === id)) {
        const status = this.status.find((elem) => elem.id === id);
        return `<div class="d-flex align-items-center h-100"><span class="badge" style="font-size: .9rem; background-color: ${status.color};">${status.label}</span></div>`;
      } else {
        return "Aucun";
      }
    },
    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event) {
      if (this.archive) {
        this.$refs["sidebarIntervention"].getInterventionArchivedDetails(event.data.id, true);
      } else {
        this.$refs["sidebarIntervention"].getInterventionDetails(event.data.id);
      }
      this.$store.commit("SET_IS_OPEN_SIDEBAR_INTERVENTION", true);
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchIntervention);
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    dateConvert(datePull) {
      const date = new Date(datePull);
      const jour = date.getDate();
      const mois = date.getMonth() + 1;
      const annee = date.getFullYear();
      const heures = date.getHours();
      const minutes = date.getMinutes();
      const dateFormatee = `${jour < 10 ? "0" + jour : jour}/${mois < 10 ? "0" + mois : mois}/${annee} ${heures < 10 ? "0" + heures : heures}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      return dateFormatee;
    },
    // handleConfirm(date) {
    //   let tempTime = new Date(date);
    //   console.log("tempTime",tempTime.toISOString());
    //   let newTime = tempTime.toLocaleTimeString("fr-FR").slice(0, 5);
    //   // console.log("newDate", tempTime.toLocaleTimeString());
    //   // props.onPress(newTime);
    // },
    getCollaboratorName(id) {
      const collaborator = this.collaboratorsList.find((elem) => elem.id == id);
      const collaboratorArchived = this.collaboratorsListArchived.find((elem) => elem.id === id);
      if (collaborator) {
        return collaborator.firstName + " " + collaborator.lastName;
      } else if (collaboratorArchived) return collaboratorArchived.firstName + " " + collaboratorArchived.lastName;
      else return "";
    },
    pool(id) {
      const pool = this.poolsList.find((elem) => elem.id == id);
      const poolArchived = this.poolsListArchived.find((elem) => elem.id == id);
      if (pool) {
        return pool.label;
      } else if (poolArchived) return poolArchived.label;
      else return "";
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    newIntervention() {
      this.$router.push({ path: "/new-intervention" });
    },
  },
};
</script>

<style lang="scss">
.pin-status {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
#filter-status {
  width: 16vw;

  & ::placeholder {
    color: #b9b9c3;
  }
}
</style>
